<template>
    <v-container>
        <v-alert type="info" text outlined>
            These settings are synced throughout the app, no matter where they're set
        </v-alert>

        <sn-slider/>
        <batteries-slider/>
        <parallel-port/>
    </v-container>
</template>

<script>
import ParallelPort from '../components/settings/parallel-port.vue'
import BatteriesSlider from '../components/settings/batteries-slider.vue'
import snSlider from '../components/settings/sn-slider.vue'
export default {
  components: { snSlider, BatteriesSlider, ParallelPort },
    data: () => ({
    }),
}
</script>

<style>

</style>